.content-wrapper {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: grid;
  grid-template-rows: auto auto 70px;
  position: relative;
}

.footer-wrapper {
  grid-row-start: 3;
  grid-row-end: 4;
}

.suggested-component {
  visibility: hidden;
}

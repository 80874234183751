.footer {
  padding: 0;
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.footer-content {
  height: 70px;
  margin-bottom: 0 !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-footer {
  background-color: #f2f2f2;
}

.color-footer-2 {
  background-color: #1c323e;
}

.color-footer-3 {
  background-color: #313333;
}
.color-footer-gri {
  background-color: #181818;
}

.footer-sign {
  height: 30px;
}

.suggested-alert-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
}

.suggested-alert {
  position: fixed;
  background-color: #002e3cc7;
  height: 97vh;
  height: calc(var(--vh, 1vh) * 97);
  box-sizing: border-box;
  width: 96%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suggested-alert-sug {
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 10px;
  position: fixed;
  right: 10px;
  top: 20px;
  animation-iteration-count: infinite;
}

.suggested-alert-cross {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  position: fixed;
  left: 10px;
  top: 22px;
}

.suggested-alert-arrow {
  height: 100px;
  position: fixed;
  right: 100px;
  top: 45px;
}

.suggested-alert-text {
  position: fixed;
  color: #ffffff;
  font-weight: 500;
}

.topbar-wrapper {
  height: 240px;
}

.topbar {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}

.color-1 {
  background-color: #fff;
}
.color-2 {
  background-color: #1c323e;
}
.color-3 {
  background-color: #181a1b;
}
.color-ufo {
  background-color: #d5dbe2;
}
.color-gri {
  background-color: #181818;
}

/* Main */

.topbar-logo-wrapper {
  height: 80px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 10px;
}

.topbar-logo {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.topbar-sug {
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 2;
}

.topbar-fav-button {
  padding: 1px !important;
  margin: 1px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  position: absolute !important;
  right: 50px;
  top: 20px !important;
  z-index: 2 !important;
}

.topbar-fav {
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 10px;
}

/* Navigation */

.topbar-navigation {
  margin-top: 95px;
  z-index: 999;
  overflow-y: hidden;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 12px;
  text-align: center;
  display: flex;
  gap: 25px;
  width: 100%;
  overflow-x: auto;
}

.top-navigation-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
}

.topbar-navigation-item {
  vertical-align: top;
  cursor: pointer;
  display: inline-block;
  margin: 0 1px;
  color: #002e3c;
  font-weight: 500;
  font-size: 14px;
  justify-content: flex-start !important;
}

.topbar-icon-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-icon-container,
.topbar-item-border {
  filter: opacity(0.5);
}

.topbar-item-border-language {
  filter: opacity(1);
}

.topbar-img {
  text-align: center;
  margin: 0 auto;
  height: 40px;
  width: 40px;
}
.filter-b {
  filter: hue-rotate(163deg) saturate(180%) brightness(135%);
}

.filter-c {
  filter: hue-rotate(200deg) saturate(10%) brightness(380%);
}

.filter-d {
  filter: hue-rotate(200deg) saturate(10%) brightness(380%);
}

.icon-active {
  filter: opacity(1) !important;
}

.topbar-item-border {
  width: 65px;
  height: 65px;
  position: relative;
}

.topbar-item-border-selected {
  width: 65px;
  height: 65px;
  position: relative;
}

.topbar-icon-container {
  width: 65px;
  height: 65px;
  position: absolute;
}

.topbar-item-name {
  width: 55px;
  white-space: pre-line;
  color: #003c12;
  margin: 9px 0px 12px 0px !important;
}

.topbar-item-name-gri {
  width: 55px;
  white-space: pre-line;
  color: #DF1F25;
}

.item-name-opacity {
  filter: opacity(0.5);
}

.item-name-divisor {
  margin: 5px auto 0 auto;
  height: 1px !important;
  width: 18px;
  background-color: #ffc000;
}

.topbar-icon {
  height: 25px;
}

/* Favoritos */

.favourite-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #4b444496;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed !important;
  z-index: 9999 !important;
}

.favourite-form {
  min-width: 200px;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 10px !important;
}

.divisor-favourite {
  height: 2px !important;
  width: 200px;
  display: block;
  margin: auto;
}

.favourite-logo-wrapper {
  width: 95px;
  height: 30px;
}

.favourite-logo {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.favourite-form-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.favourite-fav-icon {
  height: 20px;
  width: 20px;
}

@media (min-width: 640px) {
  .topbar-navigation {
    margin-top: 95px;
    z-index: 999;
    overflow-y: hidden;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 25px;
    width: 100%;
    overflow-x: auto;    
  }
}
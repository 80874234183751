.slider-menu {
  width: 100vw;
  margin-top: 20px;
}

.slider-menu .dot {
  background-color: rgb(136, 130, 130) !important;
}

.slider-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 50px;
  min-height: 250px;
  width: 100%;
  position: relative;
  background-size: cover;
}

@media only screen and (max-width: 800px) {
  .slider-menu-item {
    background-size: 100% 127%;
  }
}

.subcateg-item-logo-wrapper {
  width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.subcateg-item-logo {
  padding: 5px;
  object-fit: contain;
}

.subcateg-item-img-wrapper {
  width: 110px !important;
  height: 120px !important;
}

.subcateg-item-img-wrapper-gri {
  width: 170px !important;
  height: 180px !important;
}

.subcateg-item-img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.subcateg-item-img-back {
  z-index: -1;
  top: 70px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
  width: 200px;
  position: absolute !important;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background: #ffc637b1; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffc637b1,
    #ff7f081e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffc6376b,
    #ff7f0807
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.subcateg-item-content-wrapper {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.subcateg-item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.subcateg-item-sug {
  width: 15px !important;
  height: 15px;
  margin: 0;
  margin-bottom: 5px;
}

.subcateg-item-title {
  max-height: 40px;
  text-align: left;
  font-family: "Merriweather", serif;
  font-weight: lighter;
  line-height: 15px !important;
  text-transform: uppercase;
  font-size: 14px;
}

.subcateg-item-name {
  margin-top: 5px !important;
  text-align: left;
  font-family: "Merriweather", serif;
  font-weight: lighter;
  line-height: 15px !important;
  font-style: italic;
  text-transform: uppercase;
  font-size: 13px;
  color: rgb(143, 143, 143);
}

.subcateg-item-price {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 16px;
}

@media (max-width: 335px) {
  .subcateg-item-title {
    font-size: 13px;
  }
}

@media (max-width: 435px) {
  .slider-menu-item {
    min-height: 300px;
  }
}

@media (max-width: 337px) {
  .slider-menu-item {
    min-height: 325px;
  }
}



.restaurant-info-text {
  margin: 50px 50px 10px 50px !important;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto Slab", serif;
  color: #1f2425;
  letter-spacing: 1px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}

.restaurant-info-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.restaurant-info-cards-text {
  font-size: 13px;
  letter-spacing: 0px;
}

.restaurant-info-cards-icon {
  height: 25px;
}

.restaurant-info-visa-item {
  margin: 10px;
  height: 20px;
}

.restaurant-info-american-item {
  margin: 10px;
  height: 50px !important;
}

.restaurant-info-master-item {
  margin: 10px;
  height: 30px;
}

.restaurant-info-mercado-pago-item {
  margin: 10px;
  height: 30px;
}

.restaurant-info-cardlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.restaurant-info-img {
  max-width: 400px;
  width: 100%;
}

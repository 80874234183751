/* Close section above */

.advertising-close {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100% !important;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.advertising-close-text {
  font-size: 11px;
  color: #ffffff;
  margin-top: 4px;
  margin-right: 6px;
}

.advertising-close-logo {
  height: 20px !important;
  width: 20px !important;
}

.advertising-container {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0a0e19;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
}

.advertising-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .advertising-img {
    object-fit: contain;
  }
}

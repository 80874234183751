.auspice-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.auspice-img {
  margin: 2px;
  width: 40px;
}

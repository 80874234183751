.navbar-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.bm-menu-wrap {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  top: 0 !important;
}

.bm-menu-background-1 {
  background-color: #fff;
}
.bm-menu-background-2 {
  background-color: #1c323e;
}
.bm-menu-background-3 {
  background-color: #373737;
}
.bm-menu-background-gri {
  background: linear-gradient(90deg, #991f00, #f59921, #991f00);
}
.bm-menu-background-ufo {
  background: #ffc000;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.bm-item-list {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bm-item,
.menu-item {
  margin-top: 45px !important;
  margin: 0 auto;
  align-self: baseline;
  font-size: 15px;
  font-weight: 700;
  display: inline-block;
  text-decoration: none !important;
  transition: color 0.3s;
  text-align: center;
}
.color-item {
  color: #002e3c;
}
.color-item-2 {
  color: #a32727;
}
.color-item-3 {
  color: #948d79;
}
.color-item-4 {
  color: #968e7a;
}
.color-item-gri {
  color: #ffffff;
}
.color-item-NC {
  color: #0a350d;
}
.color-item-ufo {
  color: #fff;
}

.menu {
  max-width: 310px !important;
  width: 100% !important;
  position: absolute;
}

.menu-item {
  width: 95px;
  display: flex;
  flex-direction: column;
}

.bm-burger-button {
  padding: 10px;
  position: relative;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 20px;
}

.bm-burger-button:hover {
  background-color: rgba(128, 128, 128, 0.089);
  border-radius: 50%;
}

.bm-burger-bars {
  background: #727275;
}

.bm-cross-button {
  position: fixed;
  left: 15px !important;
  top: 15px !important;
  height: 20px !important;
}

.bm-menu {
  width: 100%;
  font-size: 15px;
}

.bm-morph-shape {
  fill: #ffffff;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navbar-footer {
  margin-top: 0 !important;
  margin: 0;
  padding: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navbar-thx {
  height: 50px;
  margin-bottom: 20px;
}

.navbar-description {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
}

.navbar-text {
  width: 200px;
  margin: 4px;
  padding: 0;
  text-align: center;
}
.color-text {
  color: #002e3c;
}
.color-text-2 {
  color: #a32727;
}
.color-text-3 {
  color: #948d79;
}
.color-text-4 {
  color: #968e7a;
}
.color-text-gri {
  color: #ffffff;
}

.navbar-division {
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.navbar-sign-content {
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 5px;
}

.navbar-fixed-image {
  margin-left: auto !important;
  margin-right: auto !important;
}

.color-sign {
  background-color: #f2f2f2;
}

.color-sign-2 {
  background-color: #1c323e;
}

.color-sign-3 {
  background-color: #181a1b;
}
.color-sign-gri {
  background: linear-gradient(90deg, #991f00, #f59921, #991f00);
  z-index: 2;
}

.navbar-sign {
  height: 30px;
}

.navbar-social-icon {
  font-size: 25px !important;
  margin-right: 5px;
  margin-left: 5px;
}
.navbar-social-icon-NC {
  font-size: 25px !important;
  margin-right: 5px;
  margin-left: 5px;
  color: #efb810 !important;
}
.color-icon {
  color: #002e3c !important;
}
.color-icon-2 {
  color: #a32727 !important;
}
.color-icon-3 {
  color: #948d79 !important;
}
.color-icon-4 {
  color: #968e7a !important;
}
.color-icon-NC {
  color: #daa520 !important;
}

.color-icon-gri {
  color: #ffffff !important;
}

.navbar-image {
  width: 55%;
  margin-bottom: 50px;
  filter: invert(1);
}

.navbar-image-back {
  z-index: -1;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 155px !important;
  width: 100% !important;
  position: absolute !important;
  border-top-left-radius: 155px;
  border-top-right-radius: 155px;
  background: #e9a9066b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #e9a9066b,
    #ff7f081e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #e9a9066b,
    #ff7f0807
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.navbar-lower-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-mimenu-logo {
  width: 25px;
  height: auto;
}

.language-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.language-button{
  box-shadow: none;
  text-transform: none;
  border-radius: 50px;
  padding: 6px 12px;
  border: 1px solid;
  line-height: 1.5;
  width: 130px;
  height: 34px;
  border-color: #555555;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  cursor: pointer;
}

.language-button:focus{
  box-shadow: 0 0 0 0.1rem rgba(0,123,255,.5);
}

.language-font {
  font-size: 12px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en-position{
  right: 35px
}

.es-position{
  left: 35px
}

@media (max-width: 1200px) {
  .navbar-sign-content {
    left: 12px;
  }
}

@media (max-width: 640px) {
  .language-button {
    width: 140px;
    height: 38px;
    font-size: 12px;
  }

  .en-position{
    right: 28px
  }
  
  .es-position{
    left: 28px
  }
}


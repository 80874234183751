.header-background {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.background-logo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-logo {
  height: 23px;
  width: 23px;
}

.background-mimenu {
  width: 110px !important;
  margin-top: 30px;
}

.background-latech {
  height: 80px;
  width: 80px;
}

.animate__animated.animate__fadeIn {
  --animate-duration: 4s;
}

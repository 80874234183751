.suggestedv2-grid {
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 480px;
}

.suggestedv2-button {
  padding: 1px !important;
  margin: 1px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  position: absolute !important;
  right: 10px !important;
  top: 20px !important;
  z-index: 2 !important;
}

.suggestedv2-sug-img {
  height: 20px;
  width: auto;
  object-fit: contain;
}

.suggestedv2-grid .control-dots {
  top: -15px;
  padding-top: 5px !important;
  height: 30px;
}

.suggestedv2-grid .dot {
  background-color: #ffffff !important;
}

.suggestedv2-carousel {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}

.suggestedv2 {
  border-radius: 40px !important;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 80%;
  background: #050f18; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #26b7ca,
    #050f18
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #26b7ca,
    #050f18
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media (min-width: 500px) {
  .suggestedv2 {
    width: 400px;
  }
  .suggestedv2-carousel {
    width: 400px;
  }
}

.carousel,
.slide,
.selected,
.carousel-root .carousel-slider,
.animated,
.slider-wrapper,
.axis-horizontal {
  background-color: #ffffff00 !important;
  background: #ffffff00 !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0s ease-in !important;
}

.control-arrow:hover {
  background-color: #ffffff00 !important;
  transition-duration: 0s !important;
}

.carousel .control-next::before,
.carousel.carousel-slider .control-next::before {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2c2b2b;
  transition-duration: 0s !important;
}

.carousel .control-prev::before,
.carousel.carousel-slider .control-prev::before {
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid transparent !important;
  border-right: 8px solid#2c2b2b !important;
  transition-duration: 0s !important;
}

.suggestedv2-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 480px;
  height: calc(var(--vh, 1vh) * 70);
  margin-top: 20px;
}

.suggestedv2-text {
  position: fixed;
  color: #575756;
  font-weight: 500;
}

.suggestedv2-item-content {
  height: 100%;
  overflow-y: auto !important;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.suggestedv2-title,
.suggestedv2-price,
.suggestedv2-description,
.suggestedv2-img-container {
  font-family: "Montserrat", sans-serif;
  justify-content: center !important;
  text-align: center !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  padding: 5px;
  color: #ffffff;
  margin: 0 !important;
  padding: 0 !important;
}

.suggestedv2-img-container {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  height: 300px !important;
  width: 100% !important;
}

.suggestedv2-img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.suggestedv2-img-back {
  z-index: -1;
  bottom: 0 !important;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 140px !important;
  width: 280px !important;
  position: absolute !important;
  border-top-left-radius: 140px;
  border-top-right-radius: 140px;
  background: #d87b236b; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #d87b236b,
    #ff7f0807
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #d87b236b,
    #ff7f0807
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.suggestedv2-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.suggestedv2-title {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: 500;
  font-size: 20px;
  grid-row-start: 2;
  grid-row-end: 3;
}

.suggestedv2-description {
  font-family: "Merriweather", serif !important;
  text-transform: uppercase;
  font-size: 13px;
  grid-row-start: 3;
  grid-row-end: 4;
  font-weight: lighter !important;
}

.suggestedv2-price {
  padding-top: 5px !important;
  font-size: 20px;
  grid-row-start: 4;
  grid-row-end: 5;
  font-weight: 400;
}

.suggestedv2-top {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 7px;
  height: 60px;
}

.suggestedv2-cross-button {
  padding: 0;
  margin: 0;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.suggestedv2-cross {
  height: 14px;
  width: 14px !important;
}

.suggestedv2-logo-wrapper {
  width: 95px;
  height: 30px;
}

.suggestedv2-logo {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.suggestedv2-sug-icon {
  height: 20px;
  width: 20px !important;
}

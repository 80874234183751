@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Castoro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500&display=swap");

@font-face {
  font-family: "Minion Pro";
  font-style: normal;
  font-weight: normal;
  src: local("Minion Pro Regular"),
    url("fonts/MinionPro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: normal;
  src: local("Knockout HTF34 JuniorSumo"),
    url("fonts/Knockout-HTF34-JuniorSumo.ttf") format("ttf");
}

@font-face {
  font-family: "Knockout";
  font-style: normal;
  font-weight: normal;
  src: local("Knockout HTF30 JuniorWelterwt"),
    url("fonts/Knockout-HTF30-JuniorWelterwt.ttf") format("ttf");
}

@font-face {
  font-family: "Adobe Caslon Pro";
  font-style: normal;
  font-weight: normal;
  src: local("A Caslon Pro"),
    url("fonts/ACaslonPro-Regular.ttf") format("ttf");
}

html body {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden !important;
  margin: 0;
}

* {
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent;
}

.section,
.circular-progress,
.main {
  position: relative;
  height: 100vh;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
}

.circular-progress,
.main {
  justify-content: center;
  align-items: center;
}

.MuiButtonBase-root {
  cursor: pointer !important;
}

.main {
  flex-direction: column;
}

.main-logo {
  width: 50px;
}

.main-logo-text {
  margin-top: 30px;
  height: 20px;
}

/* Dot of the carousel*/

.dot {
  background: #002e3c !important;
  box-shadow: none !important;
}

.suggested-grid {
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 350px;
}

.suggested-button {
  padding: 1px !important;
  margin: 1px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  position: absolute !important;
  right: 10px !important;
  top: 20px !important;
  z-index: 2 !important;
}

.suggested-sug-img {
  height: 20px;
  width: auto;
  object-fit: contain;
}

.suggested-carousel {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}

.carousel-next-button {
  transition: all 0s ease-in !important;
  top: 0;
  bottom: 0;
  position: absolute !important;
  z-index: 2;
  right: 0;
}

.carousel-prev-button {
  transition: all 0s ease-in !important;
  top: 0;
  bottom: 0;
  position: absolute !important;
  z-index: 2;
  left: 0;
}

.suggested {
  border-radius: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 80%;
}
@media (min-width: 500px) {
  .suggested {
    width: 400px;
  }
  .suggested-carousel {
    width: 400px;
  }
}

.suggested-gri {
  border-radius: 40px;
  border: red;
  background: linear-gradient(
    #222020,
    #222020e8,
    #222020e8,
    #222020e8,
    rgb(27, 27, 27),
    rgb(27, 27, 27),
    rgb(27, 27, 28)
  );

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 80%;
}
@media (min-width: 500px) {
  .suggested-gri {
    width: 400px;
  }
  .suggested-carousel-gri {
    width: 400px;
  }
}

.carousel,
.slide,
.selected,
.carousel-root .carousel-slider,
.animated,
.slider-wrapper,
.axis-horizontal {
  background: #ffffff00 !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  transition: all 0s ease-in !important;
}

.control-arrow:hover {
  background-color: #ffffff00 !important;
  transition-duration: 0s !important;
}

.carousel .control-next::before,
.carousel.carousel-slider .control-next::before {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2c2b2b;
  transition-duration: 0s !important;
}

.carousel .control-prev::before,
.carousel.carousel-slider .control-prev::before {
  border-top: 8px solid transparent !important;
  border-bottom: 8px solid transparent !important;
  border-right: 8px solid#2c2b2b !important;
  transition-duration: 0s !important;
}

.suggested-item {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 350px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.suggested-text {
  position: fixed;
  color: #575756;
  font-weight: 500;
}

.carousel-next-button {
  transition: all 0s ease-in !important;
  top: 0;
  bottom: 0;
  position: absolute !important;
  z-index: 2;
  right: 0;
}

.carousel-prev-button {
  transition: all 0s ease-in !important;
  top: 0;
  bottom: 0;
  position: absolute !important;
  z-index: 2;
  left: 0;
}

.MuiIconButton-root {
  padding: 3px !important;
}

.MuiSvgIcon-root {
  font-size: 2rem !important;
}

.suggested-item-content {
  height: 100%;
  overflow-y: auto !important;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: min-content;
  align-content: center;
  margin-bottom: 30px;
  padding: 20px;
  overflow-x: hidden;
}

.suggested-item-content-gri {
  height: 100%;
  overflow-y: auto !important;
  background-color: #181818;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: min-content;
  align-content: center;
  margin-bottom: 30px;
  padding: 20px;
  overflow-x: hidden;
}

.suggested-title,
.suggested-price,
.suggested-description,
.suggested-img-container {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  margin: 10px 10px 0px 10px;
  padding: 0;
}

.suggested-img-container {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  justify-content: center;
}

.suggested-img {
  max-height: 330px;
  max-width: 200px;
  object-fit: contain;
}

.suggested-title {
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: #201231;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  overflow-wrap: anywhere;
}

.suggested-div-gri {
  background: #181818;
  width: 100%;
}

.suggested-title-gri {
  font-size: 16px;
  color: #ffffff;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 2;
}

.suggested-price {
  text-align: center;
  justify-content: center;
  font-size: 15px;
  color: #201231;
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 3;
  font-weight: 300;
}

.suggested-price-gri {

  justify-content: flex-end;
  font-size: 15px;
  color: #ffffff;
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 3;
  font-weight: 300;
}

.suggested-description {
  text-align: center;
  justify-content: center;
  font-size: 11px;
  color: #808080;
  grid-row-start: 5;
  grid-row-end: 6;
  grid-column-start: 1;
  grid-column-end: 3;
  overflow-wrap: anywhere;
}

.suggested-top {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  padding-bottom: 7px;
  height: 60px;
}

.suggested-cross-button {
  padding: 0;
  margin: 0;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.suggested-cross {
  height: 14px;
  width: 14px !important;
}

.suggested-logo-wrapper {
  width: 95px;
  height: 50px;
}

.suggested-logo {
  height: 100% !important;
  width: 100% !important;
  width: auto;
  object-fit: contain;
}

.suggested-sug-icon {
  height: 20px;
  width: 20px !important;
}

/* Close section above */

.slide-close {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100% !important;
  top: 20px;
  right: 20px;
  z-index: 2;
}

.slide-close-text {
  font-size: 12px;
  color: #002e3c;
  margin-top: 4px;
  margin-right: 6px;
  line-height: 10px !important;
  text-transform: capitalize;
}

.slide-close-logo {
  height: 20px !important;
  width: 20px !important;
}

/* Carousel */

.initial-slide {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 !important;
  margin: 0 !important;
}

/* Carousel item */

.slide-item {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-item-content,
.slide-item-content-initial {
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-item-content {
  justify-content: space-around;
}

.slide-item-content-initial {
  justify-content: center;
}

.slide-big-logo {
  margin-top: 50px;
  width: 150px !important;
}

.slide-small-logo {
  margin-top: 20px;
  width: 130px !important;
}

.slide-space {
  height: 180px;
  width: 100%;
}

.slide-img-content {
  height: 230px;
  width: 100%;
}

.slider-img {
  height: 230px;
  max-width: 230px;
}

.slide-star-icon {
  display: none !important;
}

.slide-title {
  color: #201231;
  letter-spacing: 3px;
}

.slide-description {
  color: #808080;
  font-size: 14px;
  margin-bottom: 50px;
  padding-right: 5px;
  padding-left: 5px;
}

@media (max-height: 480px) {
  .slide-small-logo,
  .slide-big-logo {
    margin-bottom: 30px;
  }

  .slide-item {
    height: 400px;
  }

  .slider-img,
  .slide-img-content {
    height: 150px;
  }
}

/* UFO POINT */

.slide-close-ufo {
  cursor: pointer;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100% !important;
  top: 90px;
  right: calc(50% - 26px);
  z-index: 2;
  color: #ffffff !important;
}

.slide-close-ufo .slide-close-text {
  color: #ffffff !important;
}

.slide-close-ufo .slide-close-logo {
  display: none;
}

.initial-slide-ufo .slide-title {
  color: #ffffff !important;
}

.initial-slide-ufo .dot {
  background: #ffffff !important;
}

.initial-slide-ufo .slide-item-content {
  justify-content: center;
}

.initial-slide-ufo .slide-big-logo,
.initial-slide-ufo .slide-small-logo {
  order: 2 !important;
}

.initial-slide-ufo .slide-big-logo {
  margin-top: 10px !important;
  width: 200px !important;
}

.initial-slide-ufo .slide-small-logo {
  margin-top: 5px !important;
  width: 160px !important;
}

.initial-slide-ufo .slide-star-icon {
  display: inline-block !important;
  width: 20px !important;
  height: auto !important;
  margin-bottom: 10px;
}

@media (max-height: 480px) {
  .slide-close-ufo {
    top: 25px;
  }
}

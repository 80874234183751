.subcateg-menu,
.subcateg {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.subcateg-featured {
  position: relative;
  margin-bottom: 20px;
}

.subcateg-name {
  color: rgba(119, 48, 10, 0.85);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1px;
}

.subcateg-divisor {
  margin: 0 auto;
  height: 1px !important;
  filter: opacity(0.5);
  width: 95vw;
  background-color: rgba(119, 48, 10, 0.85);
}
.subcateg-gri {
  margin: 0 auto;
  height: 1px !important;
  filter: opacity(0.5);
  width: 95vw;
  background-color: #DF1F25;
}
.subcateg-NC {
  margin: 0 auto;
  height: 1px !important;
  filter: opacity(0.5);
  width: 95vw;
  background-color: rgba(10, 53, 13, 0.85);
}

.divisor {
  height: 2px !important;
  width: 257px;
}

.divisor-ufo {
  margin: 15px auto 0 auto;
  height: 1px !important;
  width: 27px;
  background-color: #fff;
}

.divisor-gri {
  margin: 15px auto 0 auto;
  height: 1px !important;
  width: 27px;
  background-color: #C8C8C8;
}

.subcateg-product-list {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
  width: 95vw;
}

.subcateg-product-list-item {
  width: 100%;
  min-width: 200px;
  padding-left: 7px;
}

.subcateg-product-list-item-data {
  justify-content: start;
  display: flex;
  align-items: center;
}

.subcateg-product-list-image {
  height: 60px;
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.subcateg-product-list-title {
  display: inline-block;
  color: #575756;
  font-weight: 600;
  font-size: 22px;
  margin: 0 !important;
  overflow-wrap: anywhere;
  font-weight: 600 !important;
  padding-right: 10px;
  padding-top: 4px
}

.subcateg-product-list-sug {
  height: 12px;
  margin-right: 10px;
}
.subcateg-product-list-cond {
  height: 20px;
  margin: 10px 5px 0 5px;
}

.filter-wh {
  filter: brightness(0) invert(1);
}

.subcateg-product-list-price,
.subcateg-product-list-desc {
  font-size: 13px;
  color: #808080;
  padding-right: 15px;
  white-space: pre-line;
  overflow-wrap: anywhere;
  font-weight: 500 !important;
}

.subcateg-product-list-price {
  font-size: 15px !important;
  letter-spacing: 1px !important;
}

.category-alert {
  margin-right: 50px;
  margin-left: 50px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto Slab", serif;
  color: #1f2425;
  letter-spacing: 1px;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.2),
    0px 0px 1px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.12) !important;
}

.MuiButtonBase-root {
  cursor: default !important;
}

.MuiAccordionDetails-root {
  padding: 0px 10px 15px 10px !important;
}
.MuiAccordionDetails-root:first-of-type {
  padding: 20px 5px 15px 5px !important;
}

.heart-icon-container {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  right: 8px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 640px) {
  .subcateg-menu,
  .subcateg {
    text-align: center;
  }
  .subcateg-product-list-data{
    justify-content: center;
  }

  .heart-icon-container {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 4px;
    right: 4px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
